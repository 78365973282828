<template>
    <div ref="loadingContainer">
        <div v-if="isLive === false">
            <ul>
                <li
                    v-for="fair in fairs"
                    :key="fair.id">
                    <router-link :to="{name: 'fair', params:{slug: fair.slug}}">
                        {{ fair.title }}
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import FetchDataComposition, {Action} from "@/compositions/FetchDataComposition";
import LoadingComposition from "@/compositions/LoadingComposition";
import Fair from "@/models/Fair";
import {defineComponent, ref} from "vue";

export default defineComponent({
    name: "Home",
    setup() {
        if (process.env.NODE_ENV === 'production') {
            location.href = process.env.VUE_APP_THAFF_URL;
        }

        const loadingContainer = ref(null);
        const {
            startLoading,
            endLoading,
            isLoading
        } = LoadingComposition(loadingContainer)

        const {mounted, unMounted} = FetchDataComposition({
            actions: [
                new Action({
                    type: 'fairs/all',
                    once: true,
                })
            ],
            onLoadingStart: startLoading,
            onLoadingEnd: endLoading
        })

        return {
            startLoading,
            endLoading,
            isLoading,
            // fetch Data composition
            mounted, unMounted,
        }
    },
    computed: {
        isLive(): boolean {
            return process.env.NODE_ENV === 'production'
        },
        fairs(): Fair[] {
            return this.$store.getters['fairs/all'];
        }
    }
});
</script>

<style scoped>

</style>